import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { StCathsCommunityHistory } from 'components/StCathsCommunityHistory'
import { StCathsCommunityGeo } from 'components/StCathsCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { StCathsCommunityHero } from 'components/StCathsCommunityHero'
import { StCathsCommunityInformation } from 'components/StCathsCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchStCaths'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import PropertiesComponent from 'components/PropertiesComponentStCaths'
import { FlexDiv } from 'design-system/FlexDiv'
import { Contact } from 'components/Contact'

const AuroraPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <FlexDiv className="cityPage">
      <PropertiesComponent defaultCity="St.Catharines"/>
      </FlexDiv>
      <Contact />
    </EllicotLayout>
  )
}

export default AuroraPage
