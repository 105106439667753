
import React from 'react'
import { useQueryParam } from 'use-query-params'
import { Link } from 'gatsby'
import { SearchBar } from '../components/SearchBarStCaths'
import { NewListing } from '../components/NewListing'
import { services } from '@services'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GoogleMap } from '../components/GoogleMap'
import { Pagination } from '../components/Pagination'
import styled from 'styled-components'
import { FlexDiv } from 'design-system/FlexDiv'
import bathIcon from '../assets/icons/bath.svg'
import bedIcon from '../assets/icons/bed.svg'
import heartIcon from '../assets/icons/heart.svg'
import { appConfig } from 'config'
import { GeocoderLocation } from 'components/GeocodeInput'
import Cookies from 'js-cookie'
import { tryParseJSON } from 'utils/lifted/tryParseJson'
const CurrencyFormat = require('react-currency-format')

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 94%;
  margin: 1em 0 0;
`

const ListingCard = styled.div`
  height: 400px;
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`
const MyDiv = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  z-index: 100;
  width: 100%;
  color: white;
`
const MyDiv2 = styled.div`
  display: flex;
  width: 40%;
  padding: 5px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  background: black;
  font-size: 14px;
`
const MyDiv3 = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  width: 32%;
  padding: 5px;
  padding-top: 8px;
  align-items: center;
  background: gray;
  opacity: 0.8;
  border-radius: 5px;
  justify-content: center;
`

const ContentDiv = styled.div`
  width: 300px;
  height: 50%;
  word-break: break-all;
  word-wrap: break-word;
`
const ContentDiv2 = styled.div`
  display: flex;
  width: 300px;
  height: 15%;
  word-break: break-all;
  word-wrap: break-word;
`
const NoStyleLink = styled(Link)`
  :link {
    text-decoration: inherit;
    color: inherit;
  }

  :visited {
    text-decoration: inherit;
    color: inherit;
  }
`

const PropertiesComponent: React.FunctionComponent<{
  defaultCity: string
}> = props => {
  const [searchOptions, setSearchOptions] = React.useState<
    GetListingsOptionsType
  >()
  const [listings, setListings] = React.useState<GetListingsType>()
  const [page, setPage] = React.useState<number>(1)
  const [mapSelectedProperty, setMapSelectedProperty] = React.useState<string>()

  React.useEffect(() => {
    console.log('ran')
    if (searchOptions) {
      console.log(searchOptions)
      if (searchOptions.label) {
        console.log(searchOptions.label)
        Cookies.set('locationLabel', JSON.stringify(searchOptions.label))
      }

      console.log('ran')
      services.repliers
                .getListings(
          {
            ...searchOptions,
            label: 'St. Catharines, ON',
            page,
          },
          // Default city
          props.defaultCity,
        )

        .then(listing => {
          console.log(listing)
          setListings({
            ...listing,
            data: listing.data.slice(
              0,
              Math.min(listing.data && listing.data.length, 50),
            ),
          })
        })
    }
  }, [searchOptions, page])

  const handleSearch = (data: GetListingsOptionsType) => {
    setPage(1)

    console.log(data)
    setSearchOptions(data)
  }
  const parseQuerySearchLocation = () => {
    const [search] = useQueryParam<string>('search')
    const currentSelection = Cookies.get('currentSelection')
    const parsed = tryParseJSON(currentSelection)

    if (parsed) {
      console.log(parsed)
      const GeocodedLocation = {
                label: 'St. Catharines, ON',
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(parsed.bounds.south, parsed.bounds.west),
          new google.maps.LatLng(parsed.bounds.north, parsed.bounds.east),
        ),

        position: parsed.position,
      }

      return GeocodedLocation as GeocoderLocation
    }
  }

  return (
    <div style={{ background: '#f5f5f5' }} className="propPage propHomePage">
      <SearchBar
        initialLocation={parseQuerySearchLocation()}
        handleSearch={handleSearch}
      />
      {listings && listings.count > 0 && (
        <PaginationWrapper>
          <Pagination
            setPage={setPage}
            numPages={listings.numPages}
            currentPage={listings.page}
          />
        </PaginationWrapper>
      )}
      <NewListing listings={listings?.data || []} />
      <FlexDiv style={{ overflow: 'hidden', width: '100%', height: '600px' }} className="homepageGMaps">
        <GoogleMap
          onSelectMarker={m => setMapSelectedProperty(m.id)}
          markers={
            listings
              ? listings.data.map(l => ({
                  id: l.mlsNumber,
                  infoWindowComponent: (
                    <div>
                      <NoStyleLink to={`/listing/${l.mlsNumber}`}>
                        <ListingCard
                          style={{
                            display: 'flex',
                          }}
                          className="gMapsListingCard"
                        >
                          <MyDiv>
                            <MyDiv2>{l.lastStatus}</MyDiv2>
                            <MyDiv2 style={{ background: 'none' }}></MyDiv2>
                            <MyDiv3>{l.mlsNumber}</MyDiv3>
                          </MyDiv>
                          <img
                            src={`${appConfig.repliers.CDN_URL}/${l.images[0]}`}
                            style={{ height: '50%', width: '300px' }}
                          />

                          <ContentDiv>
                            <div style={{ padding: 15 }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignContent: 'center',
                                  paddingTop: 10,
                                }}
                              >
                                <div style={{ display: 'flex', width: '50%' }}>
                                  <h3 style={{ margin: 0 }}>{l.listPrice}</h3>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '50%',
                                    alignContent: 'flex-end',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <img src={heartIcon} style={{ width: 20 }} />
                                  <p
                                    style={{
                                      margin: 0,
                                      marginTop: 5,
                                      marginLeft: 8,
                                      fontSize: 10,
                                    }}
                                  >
                                    Add to favourites
                                  </p>
                                </div>
                              </div>
                              <p style={{ margin: '0.75em 0' }}>
                                {l.address.streetNumber} {l.address.streetName}{' '}
                                {l.address.streetSuffix}
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {/* <div style={{ display: 'flex', width: '30%' }}>

                                  <img
                                    src={areaIcon}
                                    style={{ width: 12, marginRight: 8 }}
                                  />
                                                                    <p>{listing.details.sqft}</p>

                              </div> */}
                                <div style={{ display: 'flex', width: '50%' }}>
                                  <img
                                    src={bedIcon}
                                    style={{ width: 12, marginRight: 8 }}
                                  />
                                  <p style={{ margin: '0.75em 0' }}>
                                    {l.details.numBedrooms} Beds
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '50%',
                                    placeContent: 'flex-end',
                                  }}
                                >
                                  <img
                                    src={bathIcon}
                                    style={{ width: 12, marginRight: 8 }}
                                  />
                                  <p style={{ margin: '0.75em 0' }}>
                                    {l.details.numBathrooms} Baths
                                  </p>
                                </div>
                              </div>
                            </div>
                          </ContentDiv>
                        </ListingCard>
                      </NoStyleLink>
                    </div>
                  ),
                  showInfoWindow: mapSelectedProperty === l.mlsNumber,
                  location: new google.maps.LatLng(
                    parseFloat(l.map.latitude),
                    parseFloat(l.map.longitude),
                  ),
                }))
              : []
          }
        />
      </FlexDiv>
    </div>
  )
}

export default PropertiesComponent
